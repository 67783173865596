<template>
  <base-modal @close="close">
    <div data-cy="category-modal-header" slot="header" class="modal-title">Add Search Term</div>

    <div class="max-w-610 space-y-4 p-6 md:w-591">
      <div class="grid gap-y-2">
        <h2 class="mb-4">Words</h2>

        <base-text-input
          :placeholder="isAdvancedSearchEnabled ? 'This exact phrase' : 'Please type a search term'"
          v-model="exactPhrase"
          data-cy="feed-search-term-input"
        />
        <label>Example: happy hour · contains the exact phrase “happy hour”</label>

        <div class="my-4 flex items-center justify-between">
          <h3>Display the advanced settings</h3>
          <base-toggle v-model="isAdvancedSearchEnabled" />
        </div>
      </div>
      <transition
        enter-active-class="transform transition duration-300 ease-in-out"
        enter-class="-translate-y-11/12 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition transform duration-150 ease-in"
        leave-class="translate-y-0 opacity-100"
        leave-to-class="-translate-y-11/12 opacity-0"
      >
        <div class="grid gap-y-2" v-if="isAdvancedSearchEnabled">
          <base-text-input placeholder="None of these words" v-model="noneOfTheseWords" />
          <label>Example: cats dogs · does not contain “cats” and does not contain “dogs”</label>

          <base-text-input placeholder="These hashtags" v-model="hashtags" />
          <label>Example: #ThrowbackThursday · contains the hashtag #ThrowbackThursday</label>

          <base-select
            :options="languages"
            placeholder="Languages"
            v-model="selectedLanguage"
            labelAttribute="label"
            valueAttribute="value"
          />

          <h2 class="my-4">Accounts</h2>

          <base-text-input
            placeholder="From these accounts"
            class="mb-2"
            v-model="fromTheseAccounts"
          />

          <label>Example: @Twitter · sent from @Twitter</label>

          <base-text-input placeholder="To these accounts" class="mb-2" v-model="toTheseAccounts" />
          <label>Example: @Twitter · sent in reply to @Twitter</label>

          <base-text-input
            placeholder="Mentioning these accounts"
            class="mb-2"
            v-model="mentioningTheseAccounts"
          />
          <label>Example: @SFBART @Caltrain · mentions @SFBART or mentions @Caltrain</label>
          <h2 class="my-4">Filters</h2>

          <div class="mb-4 flex items-center justify-between">
            <h3>Links</h3>
            <base-toggle v-model="linkFilterSettings.enabled" />
          </div>

          <template v-if="linkFilterSettings.enabled">
            <div class="mb-2 flex justify-between">
              <p>Include Tweets with links</p>
              <new-base-radio
                value="all"
                v-model="linkFilterSettings.filter"
                name="link-settings"
              />
            </div>
            <div class="flex justify-between">
              <p>Only show links with tweets</p>
              <new-base-radio
                value="only"
                v-model="linkFilterSettings.filter"
                name="link-settings"
              />
            </div>
          </template>
        </div>
      </transition>
      <div class="flex w-full justify-end" v-if="!advancedSettingsToEdit">
        <tooltip :disabled="!createOrUpdateMessageError" :content="createOrUpdateMessageError">
          <span>
            <button
              @click="createAdvancedSearchSettings"
              :disabled="createOrUpdateMessageError"
              class="button small-button bg-main-color-100 text-white"
              :class="
                createOrUpdateMessageError
                  ? 'cursor-not-allowed bg-gray-60'
                  : 'cursor-pointer bg-main-color-50'
              "
              data-cy="feed-add-search-add-button"
            >
              Add
            </button>
          </span>
        </tooltip>
      </div>
      <div class="flex w-full justify-end space-x-2" v-else>
        <tooltip :disabled="!createOrUpdateMessageError" :content="createOrUpdateMessageError">
          <span>
            <button
              @click="updateAdvancedSearchSettings"
              class="button small-button bg-main-color-100 text-white"
              :class="
                createOrUpdateMessageError
                  ? 'cursor-not-allowed bg-gray-60'
                  : 'cursor-pointer bg-main-color-50'
              "
              :disabled="createOrUpdateMessageError"
            >
              Update
            </button>
          </span>
        </tooltip>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      languages() {
        return [
          { label: 'Amharic', value: 'am' },
          { label: 'Arabic', value: 'ar' },
          { label: 'Armenian', value: 'hy' },
          { label: 'Basque', value: 'eu' },
          { label: 'Bengali', value: 'bn' },
          { label: 'Bosnian', value: 'bs' },
          { label: 'Bulgarian', value: 'bg' },
          { label: 'Burmese', value: 'my' },
          { label: 'Croatian', value: 'hr' },
          { label: 'Catalan', value: 'ca' },
          { label: 'Czech', value: 'cs' },
          { label: 'Danish', value: 'da' },
          { label: 'Dutch', value: 'nl' },
          { label: 'English', value: 'en' },
          { label: 'Estonian', value: 'et' },
          { label: 'Finnish', value: 'fi' },
          { label: 'French', value: 'fr' },
          { label: 'Georgian', value: 'ka' },
          { label: 'German', value: 'de' },
          { label: 'Greek', value: 'el' },
          { label: 'Gujarati', value: 'gu' },
          { label: 'Haitian Creole', value: 'ht' },
          { label: 'Hebrew', value: 'iw' },
          { label: 'Hindi', value: 'hi' },
          { label: 'Latinized Hindi', value: 'hi-Latn' },
          { label: 'Hungarian', value: 'hu' },
          { label: 'IceLandic', value: 'is' },
          { label: 'Indonesian', value: 'in' },
          { label: 'Italian', value: 'it' },
          { label: 'Japanese', value: 'ja' },
          { label: 'Kannada', value: 'kn' },
          { label: 'Khmer', value: 'km' },
          { label: 'Korean', value: 'ko' },
          { label: 'Lao', value: 'lo' },
          { label: 'Latvian', value: 'lv' },
          { label: 'Lithuanian', value: 'lt' },
          { label: 'Malayalam', value: 'ml' },
          { label: 'Maldivian', value: 'dv' },
          { label: 'Marathi', value: 'mr' },
          { label: 'Nepali', value: 'ne' },
          { label: 'Norwegian', value: 'no' },
          { label: 'Oriya', value: 'or' },
          { label: 'Panjabi', value: 'pa' },
          { label: 'Pashto', value: 'ps' },
          { label: 'Persian', value: 'fa' },
          { label: 'Polish', value: 'pl' },
          { label: 'Portugues', value: 'pt' },
          { label: 'Romanian', value: 'ro' },
          { label: 'Russian', value: 'ru' },
          { label: 'Serbian', value: 'sr' },
          { label: 'Simplified Chinese', value: 'zh-CN' },
          { label: 'Sindhi', value: 'sd' },
          { label: 'Sinhala', value: 'si' },
          { label: 'Slovak', value: 'sk' },
          { label: 'Slovenian', value: 'sl' },
          { label: 'Sorani Kurdish', value: 'ckb' },
          { label: 'Spanish', value: 'es' },
          { label: 'Swedish', value: 'sv' },
          { label: 'Tagalog', value: 'tl' },
          { label: 'Tamil', value: 'ta' },
          { label: 'Telugi', value: 'te' },
          { label: 'Thai', value: 'th' },
          { label: 'Tibetan', value: 'bo' },
          { label: 'Traditional Chinese', value: 'zh-TW' },
          { label: 'Turkish', value: 'tr' },
          { label: 'Ukrainian', value: 'uk' },
          { label: 'Urdu', value: 'ur' },
          { label: 'Uyghur', value: 'ug' },
          { label: 'Vietnamese', value: 'vi' },
          { label: 'Welsh', value: 'cy' },
        ];
      },
      createOrUpdateMessageError() {
        if (
          this.isAdvancedSearchEnabled &&
          !this.words &&
          !this.exactPhrase &&
          !this.anyOfTheseWords &&
          !this.noneOfTheseWords &&
          !this.hashtags
        ) {
          return 'Fill one of the Words fields';
        } else if (!this.isAdvancedSearchEnabled && !this.exactPhrase) {
          return 'Provide a search term';
        }
        return null;
      },
    },
    data() {
      return {
        selectedLanguage: null,
        words: null,
        exactPhrase: null,
        anyOfTheseWords: null,
        noneOfTheseWords: null,
        hashtags: null,
        fromTheseAccounts: null,
        toTheseAccounts: null,
        mentioningTheseAccounts: null,
        linkFilterSettings: {
          enabled: false,
          filter: 'all',
        },
        isAdvancedSearchEnabled: false,
      };
    },
    methods: {
      close() {
        this.$emit('close');
      },
      resetModal() {
        this.selectedLanguage = null;
        this.words = null;
        this.exactPhrase = null;
        this.anyOfTheseWords = null;
        this.noneOfTheseWords = null;
        this.hashtags = null;
        this.fromTheseAccounts = null;
        this.toTheseAccounts = null;
        this.mentioningTheseAccounts = null;
        this.linkFilterSettings = {
          enabled: false,
          filter: 'all',
        };
      },
      createAdvancedSearchSettings() {
        const advancedSearchSettings = {
          language: this.selectedLanguage,
          words: this.words,
          exactPhrase: this.exactPhrase,
          noneOfTheseWords: this.noneOfTheseWords,
          anyOfTheseWords: this.anyOfTheseWords,
          hashtags: this.hashtags,
          fromTheseAccounts: this.fromTheseAccounts,
          toTheseAccounts: this.toTheseAccounts,
          mentioningTheseAccounts: this.mentioningTheseAccounts,
          ...(this.linkFilterSettings.enabled
            ? { linkFilter: this.linkFilterSettings.filter }
            : {}),
          type: this.isAdvancedSearchEnabled ? 'advanced' : 'simple',
        };
        this.$emit('create', advancedSearchSettings);
      },
      updateAdvancedSearchSettings() {
        const updatedAdvancedSearchSettings = {
          language: this.selectedLanguage,
          words: this.words,
          exactPhrase: this.exactPhrase,
          noneOfTheseWords: this.noneOfTheseWords,
          anyOfTheseWords: this.anyOfTheseWords,
          hashtags: this.hashtags,
          fromTheseAccounts: this.fromTheseAccounts,
          toTheseAccounts: this.toTheseAccounts,
          mentioningTheseAccounts: this.mentioningTheseAccounts,
          ...(this.linkFilterSettings.enabled
            ? { linkFilter: this.linkFilterSettings.filter }
            : {}),
          type: this.isAdvancedSearchEnabled ? 'advanced' : 'simple',
        };
        this.$emit('update', this.advancedSettingsToEdit.index, updatedAdvancedSearchSettings);
      },
    },
    created() {
      if (!this.advancedSettingsToEdit || !this.advancedSettingsToEdit.settings) {
        this.resetModal();
        return;
      }
      const { settings } = this.advancedSettingsToEdit;
      this.isAdvancedSearchEnabled = settings.type === 'advanced';
      this.selectedLanguage = settings.language || null;
      this.linkFilterSettings = {
        enabled: Boolean(settings.linkFilter),
        filter: settings.linkFilter,
      };
      this.words = settings.words && settings.words !== '' ? settings.words : null;
      this.hashtags = settings.hashtags && settings.hashtags !== '' ? settings.hashtags : null;
      this.exactPhrase =
        settings.exactPhrase && settings.exactPhrase !== '' ? settings.exactPhrase : null;
      this.anyOfTheseWords =
        settings.anyOfTheseWords && settings.anyOfTheseWords !== ''
          ? settings.anyOfTheseWords
          : null;
      this.noneOfTheseWords =
        settings.noneOfTheseWords && settings.noneOfTheseWords !== ''
          ? settings.noneOfTheseWords
          : null;
      this.fromTheseAccounts =
        settings.fromTheseAccounts && settings.fromTheseAccounts !== ''
          ? settings.fromTheseAccounts
          : null;
      this.toTheseAccounts =
        settings.toTheseAccounts && settings.toTheseAccounts !== ''
          ? settings.toTheseAccounts
          : null;
      this.mentioningTheseAccounts =
        settings.mentioningTheseAccounts && settings.mentioningTheseAccounts !== ''
          ? settings.mentioningTheseAccounts
          : null;
    },
    name: 'advanced-tweet-search-modal',
    props: {
      show: Boolean,
      advancedSettingsToEdit: {
        type: Object,
        default: null,
      },
    },
    watch: {
      isAdvancedSearchEnabled: function (val) {
        if (val) {
          this.$eventStore.engagementBuilder.settings('Add search term: Display advanced settings: Enabled');
        } else {
          this.$eventStore.engagementBuilder.settings('Add search term: Display advanced settings: Disabled');
        }
      }
    },
  };
</script>
