<template>
  <base-modal data-cy="get_list_members" @close="close" :loading="isLoading" :title="list.name">
    <div class="max-w-610 p-8 md:w-466">
      <div v-if="twitterListMembers.length">
        <new-alert class="mb-6" v-if="maxAllowedWatchedUsers < twitterListMembers.length">
          Please remove {{ numberOfMembersToRemove }} member{{ pluralizeMember }} from the list.
          <br />
          Maximum allowed number of members to add: {{ maxAllowedWatchedUsers }}.
        </new-alert>
        <ul class="w-60 m-0 list-none">
          <li class="my-4" v-for="(member, i) in twitterListMembers" v-bind:key="member.id">
            <base-text-input v-model="member.username" disabled>
              <template #icon v-if="twitterListMembers.length > 1">
                <button @click="removeTwitterListMember(i)">
                  <inline-svg src="/img/icons/delete.svg" />
                </button>
              </template>
            </base-text-input>
          </li>
        </ul>
      </div>
      <new-button
        data-cy="confirm_button"
        @click="sendMembersList"
        :disabled="maxAllowedWatchedUsers < twitterListMembers.length"
        class="ml-auto mt-6"
      >
        Confirm
      </new-button>
    </div>
  </base-modal>
</template>

<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import controller from '@/controller';
  export default {
    name: 'twitter-list-members-modal',
    props: {
      list: {
        type: Object,
        required: true,
      },
      maxAllowedWatchedUsers: {
        type: Number,
        required: true,
      },
      show: {
        type: Boolean,
      },
    },
    async created() {
      await this.fetchTwitterListMembers();
    },
    data() {
      return {
        isLoading: false,
        twitterListMembers: [],
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      numberOfMembersToRemove() {
        if (this.maxAllowedWatchedUsers < this.twitterListMembers.length) {
          return this.twitterListMembers.length - this.maxAllowedWatchedUsers;
        }
        return 0;
      },
      pluralizeMember() {
        if (this.numberOfMembersToRemove === 1) {
          return '';
        }
        return 's';
      },
    },
    methods: {
      async fetchTwitterListMembers() {
        try {
          this.isLoading = true;
          this.twitterListMembers = await controller.twitter.getTwitterListMembers(
            this.currentUser,
            this.userProfile.uid,
            this.list.id,
          );
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: 'An error has occurred while trying to get your twitter list members',
            type: 'error',
          });
          this.close();
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      },
      removeTwitterListMember(i) {
        this.twitterListMembers.splice(i, 1);
      },
      close() {
        this.$emit('reset-twitter-list');
        this.$emit('close');
      },
      sendMembersList() {
        this.$emit('send-members-list', this.twitterListMembers);
        this.$eventStore.engagementBuilder.settings('Import users: Confirm');
        this.$emit('close');
      },
    },
    mixins: [SwalModalMixin],
  };
</script>
