<script>
  export default {
    data() {
      return {
        advancedSettingsToEdit: null,
        isAdvancedTweetSearchModalOpened: false,
      };
    },
    methods: {
      formatAdvancedSearchInputValue(settings) {
        const value = [
          settings.words,
          settings.exactPhrase,
          settings.anyOfTheseWords,
          settings.noneOfTheseWords,
          settings.hashtags,
        ]
          .filter((el) => el)
          .join(' ');
        if (value.length > 45) {
          return `${value.slice(0, 45)}...`;
        }
        return value;
      },
      openTwitterAdvancedSearchModal() {
        this.isAdvancedTweetSearchModalOpened = true;
        this.$eventStore.engagementBuilder.settings('Add search term');
      },
      closeTwitterAdvancedSearchModal() {
        this.isAdvancedTweetSearchModalOpened = false;
        this.advancedSettingsToEdit = null;
      },
      editAdvancedSettings(i, settings) {
        this.advancedSettingsToEdit = { index: i, settings };
        this.isAdvancedTweetSearchModalOpened = true;
      },
      createAdvancedSearchSettings(settings) {
        this.searchSettings.push(settings);
        this.isAdvancedTweetSearchModalOpened = false;
      },
      updateAdvancedSearchSettings(index, updatedSettings) {
        this.searchSettings[index] = updatedSettings;
        this.advancedSettingsToEdit = null;
        this.isAdvancedTweetSearchModalOpened = false;
      },
      deleteAdvancedSearchSettings(index) {
        this.searchSettings.splice(index, 1);
      },
    },
  };
</script>
