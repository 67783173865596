<template>
  <slide-over
    enableMobileBottomToTopTranslation
    ref="composerContainer"
    @close="$emit('close')"
    :open="show"
  >
    <div class="h-full overflow-auto rounded-t-2xl bg-ds-foreground px-4 py-5">
      <div class="flex items-center justify-between">
        <h1 class="text-2xl text-primary">Keywords & Users</h1>
        <new-button icon type="custom" @click="$emit('close')">
          <inline-svg src="/img/icons/new/x.svg" class="h-8 w-8 text-ds-button-icon" />
        </new-button>
      </div>
      <slot />
    </div>
  </slide-over>
</template>

<script>
  import SlideOver from '@/components/SlideOver.vue';

  export default {
    components: { SlideOver },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
