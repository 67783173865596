<template>
  <div class="row justify-content-center" :key="source.id" :id="source.id">
    <new-queue-day-header
      v-if="source.isDate"
      :userTimezone="userProfile.timezone"
      :midnight="source.date"
    />
    <new-feed-item
      v-else
      @hideTweet="(tweetId) => hideTweet(tweetId, source.type)"
      :tweet="source"
      ref="feed-item-box"
      :isSearchFeed="source.type === 'search_terms_feed'"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import NewFeedItem from '@/components/NewFeedItem';
  import NewQueueDayHeader from '@/components/NewQueueDayHeader';

  export default {
    name: 'feed-item-container',
    props: {
      source: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    components: {
      NewFeedItem,
      NewQueueDayHeader,
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
    },
  };
</script>
