<template>
  <div v-if="!isMobileView" class="w-full lg:w-64 bg-main-canvas" data-cy="feed-view">
    <div class="lg:p-6">
      <div class="my-4 space-y-3 lg:my-0">
        <div class="flex items-center justify-between">
          <h2 class="text-xl font-bold">
            Keywords
            <span class="text-base font-medium text-ds-text-secondary-tertiary">
              ({{ searchTerms.length }})
            </span>
          </h2>
          <div class="hidden lg:block">
            <new-button
              @click="isKeywordsVisible = !isKeywordsVisible"
              icon
              type="custom"
              size="sm"
            >
              <inline-svg
                src="/img/icons/new/chevron.svg"
                class="h-5 w-5 stroke-ds-text-primary"
                :class="{
                  'rotate-180 transform': !isKeywordsVisible,
                }"
              />
            </new-button>
          </div>
        </div>
        <div v-show="isKeywordsVisible" class="space-y-3">
          <base-input
            type="text"
            v-model="keywordsSearchKeyword"
            placeholder="Search & add"
            @keyup.enter="addSearchTerm"
            @append-icon-click="addSearchTerm"
            data-cy="search-keyword-input"
          >
            <template #append>
              <inline-svg src="/img/icons/new/search.svg" data-cy="search-keyword-search" />
            </template>
          </base-input>
          <div>
            <div
              v-for="term in searchTerms"
              :key="term.exactPhrase"
              class="border pointer-events-none mb-2 mr-2 inline-flex cursor-pointer items-center justify-between rounded-md px-3 py-2 text-md lg:pointer-events-auto"
              :class="
                searchTermFilters.find((t) => t.exactPhrase === term.exactPhrase) && isDesktop
                  ? 'border-solid border-ds-button-secondary-background bg-ds-button-secondary-background'
                  : 'border-dashed border-ds-outline-primary'
              "
              @click="updateSearchTermFilter(term)"
              data-cy="search-keyword-item"
            >
              {{ term.exactPhrase }}
              <new-button
                class="pointer-events-auto"
                icon
                type="custom"
                size="sm"
                @click.stop="removeSearchTerm(term.exactPhrase)"
                data-cy="search-keyword-item-delete"
              >
                <inline-svg src="/img/icons/new/x.svg" class="text-ds-text-secondary-tertiary" />
              </new-button>
            </div>
          </div>
        </div>
      </div>
      <div class="my-4 space-y-3 space-y-3 lg:my-0 lg:mt-5">
        <div class="flex items-center justify-between">
          <h2 class="text-xl font-bold">
            Users
            <span class="text-base font-medium text-ds-text-secondary-tertiary"
              >({{ watchedUsers.length }})</span
            >
          </h2>
          <div class="hidden lg:block">
            <new-button @click="isUsersVisible = !isUsersVisible" icon type="custom" size="sm">
              <inline-svg
                src="/img/icons/new/chevron.svg"
                class="h-5 w-5 stroke-ds-text-primary"
                :class="{
                  'rotate-180 transform': !isUsersVisible,
                }"
              />
            </new-button>
          </div>
        </div>
        <div v-show="isUsersVisible" class="relative space-y-3">
          <base-input
            type="text"
            v-model="userSearchKeyword"
            @keyup.enter="getTwitterUser"
            @input="isUserNotFoundError = false"
            placeholder="Username"
            @append-icon-click="isUserNotFoundError ? (userSearchKeyword = '') : getTwitterUser()"
            :customPrependStyle="true"
            :customInputStyle="true"
            inputClasses="pl-1"
            data-cy="search-user-input"
          >
            <template #prepend>
              <span class="pl-3 text-ds-text-secondary-tertiary">@</span>
            </template>
            <template #append>
              <div>
                <Spinner v-if="isUserSearchLoading" />
                <inline-svg
                  v-else
                  :src="
                    isUserNotFoundError ? '/img/icons/close.svg' : '/img/icons/new/search.svg'
                  "
                  data-cy="search-user-search"
                />
              </div>
            </template>
          </base-input>
          <div
            v-if="isUserNotFoundError"
            class="absolute z-10 w-full rounded-xl bg-light p-2 text-center text-ds-text-secondary-tertiary shadow-md"
          >
            This user was not found
          </div>
          <div class="flex flex-col gap-y-3 lg:pb-20">
            <engagement-builder-user-profile
              has-new-posts
              v-for="(user, index) in watchedUsers"
              :key="index"
              :user="user"
              :isMobileEngagementSettings="isMobileEngagementSettings"
              @remove-user="removeUser(user)"
              @filter-by-user="updateUserFilter(user.twitterId)"
              :isSelected="userFilters.includes(user.twitterId)"
              :isUserFilterActive="isUserFilterActive"
              :isSearchTermFilterActive="isSearchTermFilterActive"
              :hasNewTweets="usersWithNewTweets.includes(user.twitterId)"
              data-cy="search-user-item"
            />
          </div>
        </div>

      </div>
    </div>
    <div class="fade-effect fixed bottom-0 hidden h-24 w-full lg:block"></div>
  </div>
  <div class="space-y-4 w-full" v-else>
    <div class="flex overflow-x-auto" style="scrollbar-width: none;">
      <engagement-builder-user-profile
        has-new-posts
        v-for="(user, index) in watchedUsers"
        :key="index"
        :user="user"
        @remove-user="removeUser(user)"
        @filter-by-user="updateUserFilter(user.twitterId)"
        :isSelected="userFilters.includes(user.twitterId)"
        :isMobileView="isMobileView"
        :isSearchTermFilterActive="isSearchTermFilterActive"
        :isUserFilterActive="isUserFilterActive"
        :hasNewTweets="usersWithNewTweets.includes(user.twitterId)"
      />
    </div>
    <div class="flex gap-x-2 overflow-x-auto" style="scrollbar-width: none;">
      <div
        v-for="term in searchTerms"
        :key="term.exactPhrase"
        class="border whitespace-no-wrap mb-2 mr-2 inline-flex cursor-pointer items-center justify-between rounded-md px-2 py-1 text-sm"
        :class="
          searchTermFilters.find((t) => t.exactPhrase === term.exactPhrase)
            ? 'border-solid border-ds-button-secondary-background bg-ds-button-secondary-background'
            : 'border-dashed border-ds-outline-primary'
        "
        @click="updateSearchTermFilter(term)"
      >
        {{ term.exactPhrase }}
      </div>
    </div>
  </div>
</template>

<script>
  import EngagementBuilderUserProfile from './EngagementBuilderUserProfile.vue';
  import Spinner from '../Loading/Spinner.vue';
  import controller from '@/controller';
  import { mapGetters } from 'vuex';
  import dao from '@/dao';
  import BreakpointsMixin from '../../views/Mixins/BreakpointsMixin.vue';

  export default {
    name: 'enagement-builder-side-bar',
    components: { EngagementBuilderUserProfile, Spinner },
    data() {
      return {
        keywordsSearchKeyword: '',
        userSearchKeyword: '',
        isKeywordsVisible: true,
        isUsersVisible: true,
        isUserSearchLoading: false,
        isUserNotFoundError: false,
      };
    },
    props: {
      userFilters: {
        type: Array,
        default: () => [],
      },
      searchTermFilters: {
        type: Array,
        default: () => [],
      },
      isMobileView: {
        type: Boolean,
        default: false,
      },
      isMobileEngagementSettings: {
        type: Boolean,
        default: false,
      },
      usersWithNewTweets: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
      searchTerms() {
        return this.userProfile?.feedSettings?.searchSettings || [];
      },
      watchedUsers() {
        return this.userProfile?.feedSettings?.watchedUsers || [];
      },
      isSearchTermFilterActive() {
        return this.searchTermFilters.length > 0;
      },
      isUserFilterActive() {
        return this.userFilters.length > 0;
      },
    },
    methods: {
      async getTwitterUser() {
        if (this.userSearchKeyword.trim() === '') {
          return;
        }
        const usernameToSearch = this.userSearchKeyword.trim().toLowerCase().replace('@', '');
        if (this.watchedUsers.find((u) => u.username.toLowerCase() === usernameToSearch)) {
          this.userSearchKeyword = '';
          return;
        }
        this.isUserSearchLoading = true;
        try {
          const twitterUsers = await controller.twitter.getTwitterUsersByUsernames(
            this.currentUser,
            this.userProfile.uid,
            [usernameToSearch],
          );
          if (twitterUsers && twitterUsers.length) {
            const updateMap = {
              'feedSettings.watchedUsers': [...this.watchedUsers, ...twitterUsers],
              'feedSettings.isFeedSettingsUpdatedRecently': true,
            };
            await dao.userProfile.updateFeedSettings(this.userProfile.uid, updateMap);
            this.userSearchKeyword = '';
            this.$emit('fetch-feed');
            this.updateUserFilter(twitterUsers[0].twitterId);
          }
        } catch (error) {
          console.error(error);
          this.isUserNotFoundError = true;
        } finally {
          this.isUserSearchLoading = false;
        }
      },
      async removeUser(user) {
        if (this.userFilters.length === 1 && this.userFilters.includes(user.twitterId)) {
          this.$emit('update-user-filter', null);
        }
        const updatedWatchedUsers = this.watchedUsers.filter((u) => u.twitterId !== user.twitterId);
        const updateMap = {
          'feedSettings.watchedUsers': updatedWatchedUsers,
          'feedSettings.isFeedSettingsUpdatedRecently': true,
        };
        await dao.userProfile.updateFeedSettings(this.userProfile.uid, updateMap);
        this.$emit('fetch-feed');
      },
      async addSearchTerm() {
        if (this.keywordsSearchKeyword.trim() === '') {
          return;
        }
        if (
          this.searchTerms.find(
            (t) => t.exactPhrase.toLowerCase() === this.keywordsSearchKeyword.trim().toLowerCase(),
          )
        ) {
          this.keywordsSearchKeyword = '';
          return;
        }
        const searchTermToAdd = {
          exactPhrase: this.keywordsSearchKeyword.trim(),
          type: 'simple',
        };
        const updatedSearchTerms = [...this.searchTerms, searchTermToAdd];
        const updateMap = {
          'feedSettings.searchSettings': updatedSearchTerms,
          'feedSettings.isFeedSettingsUpdatedRecently': true,
        };
        await dao.userProfile.updateFeedSettings(this.userProfile.uid, updateMap);
        this.keywordsSearchKeyword = '';
        this.$emit('fetch-search-feed');
        this.updateSearchTermFilter(searchTermToAdd);
      },
      async removeSearchTerm(searchTerm) {
        if (
          this.searchTermFilters.length === 1 &&
          this.searchTermFilters.find((t) => t.exactPhrase === searchTerm.exactPhrase)
        ) {
          this.$emit('update-search-term-filter', null);
        }
        const updatedSearchTerms = this.searchTerms.filter(
          (term) => term.exactPhrase !== searchTerm,
        );
        const updateMap = {
          'feedSettings.searchSettings': updatedSearchTerms,
          'feedSettings.isFeedSettingsUpdatedRecently': true,
        };
        await dao.userProfile.updateFeedSettings(this.userProfile.uid, updateMap);
        this.$emit('fetch-search-feed');
      },
      updateUserFilter(userTwitterId) {
        this.$emit('update-search-term-filter', null);
        this.$emit('update-user-filter', userTwitterId);
      },
      updateSearchTermFilter(searchTerm) {
        this.$emit('update-user-filter', null);
        this.$emit('update-search-term-filter', searchTerm);
      },
    },
    mixins: [BreakpointsMixin],
  };
</script>
