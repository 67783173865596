<template>
  <div data-cy="feed-page">
    <div class="mb-6 flex items-center gap-x-4 justify-between">
      <h1 class="flex items-center gap-x-2">
        Engagement Builder
        <tooltip v-if="$route.path === '/feed'" content="Shortcuts">
          <div @click="isShortcutsModalVisible = true" class="block cursor-pointer">
            <inline-svg src="/img/icons/info.svg" class="h-5 w-5 fill-gray" />
          </div>
        </tooltip>
      </h1>
      <new-drop-down v-if="isDesktop" buttonType="secondary">
        <template #button>
          Import users from&nbsp;<b>𝕏</b>
        </template>
        <template>
          <div class="space-y-2">
            <select
              v-model="selectedList"
              class="border border-gray-700 block cursor-pointer rounded-lg text-sm"
              data-cy="feed-choose-list"
            >
              <option :value="{}" selected disabled>
                {{
                  isFetchingTwitterLists ? 'Loading your Twitter lists...' : 'Select list'
                }}
              </option>
              <option v-for="list in twitterLists" v-bind:key="list.id" :value="list">
                {{ list.name }}
              </option>
            </select>
            <new-button @click="openTwitterListMembersModal" class="w-full">
              Import
            </new-button>
          </div>
        </template>
      </new-drop-down>
    </div>
    <div class="lg:hidden">
      <div class="mb-3 flex items-center justify-between">
        <h3>Watched users & keywords</h3>
        <new-button
          type="custom"
          class="text-ds-button-icon"
          @click="showMobileSettingsPage = true"
        >
          Edit
        </new-button>
      </div>
      <div class="without-scrollbar mb-6 overflow-x-auto lg:hidden">
        <EngagementBuilderSideBar
          @fetch-feed="fetchFeed"
          @fetch-search-feed="fetchSearchFeed"
          @update-user-filter="updateUserFilter"
          :userFilters="userFilters"
          @update-search-term-filter="updateSearchTermFilter"
          :searchTermFilters="searchTermFilters"
          :isMobileView="!isDesktop"
          :usersWithNewTweets="usersWithNewTweets"
        />
      </div>
    </div>
    <div>
      <div data-cy="feed-loader" class="loader" v-if="isFetchingFeed">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
      <template class="space-y-6" v-else>
        <new-alert v-if="areWatchedUsersMoreThanUserLimit">
          You have more than {{ maxAllowedWatchedUsers }} watched users. The others will be ignored
          unless you
          <a
            href="javascript:;"
            class="text-main-color-100"
            @click="openFeedLimitNextPlanPopUp('watchedUsers')"
            >upgrade your subscription</a
          >.
        </new-alert>

        <new-alert v-if="areWatchedSearchTermsMoreThanUserLimit">
          You have more than {{ maxAllowedWatchedSearchTerms }} search terms. The others will be
          ignored unless you
          <a
            href="javascript:;"
            class="text-main-color-100"
            @click="openFeedLimitNextPlanPopUp('watchedSearchTerms')"
            >upgrade your subscription</a
          >.
        </new-alert>

        <virtual-list
          :data-key="'id'"
          :data-sources="tweetsByDate"
          :data-component="feedContainer"
          :page-mode="true"
          :estimate-size="520"
        />
        <p v-if="tweets.length === 0">
          No feed to show.<br /><br />
          Please try adding some users on the sidebar on the right or update filters.
        </p>
      </template>
    </div>
    <portal to="layout-right-side-bar">
      <div
        class="without-scrollbar lg:border-l mb-6 hidden overflow-x-auto lg:fixed lg:right-0 lg:top-0 lg:mb-0 lg:flex lg:h-full lg:w-64 lg:overflow-y-auto lg:border-ds-outline-secondary"
      >
        <EngagementBuilderSideBar
          @fetch-feed="fetchFeed"
          @fetch-search-feed="fetchSearchFeed"
          @update-user-filter="updateUserFilter"
          :userFilters="userFilters"
          @update-search-term-filter="updateSearchTermFilter"
          :searchTermFilters="searchTermFilters"
          :usersWithNewTweets="usersWithNewTweets"
        />
      </div>
    </portal>
    <MobileEngagementSetting :show="showMobileSettingsPage" @close="showMobileSettingsPage = false">
      <EngagementBuilderSideBar
        @fetch-feed="fetchFeed"
        @fetch-search-feed="fetchSearchFeed"
        @update-user-filter="updateUserFilter"
        :userFilters="userFilters"
        @update-search-term-filter="updateSearchTermFilter"
        :searchTermFilters="searchTermFilters"
        :isMobileEngagementSettings="true"
      />
    </MobileEngagementSetting>
    <back-to-top-custom />
    <feed-and-mentions-shortcuts-modal
      v-if="isShortcutsModalVisible"
      @close="isShortcutsModalVisible = false"
    />

    <twitter-list-members-modal
      v-if="isTwitterListModalVisible"
      :list="selectedList"
      :maxAllowedWatchedUsers="maxAllowedWatchedUsers"
      @close="closeTwitterListMembersModal"
      v-on:send-members-list="setWatchedUsersFromTwitterList"
      v-on:reset-twitter-list="resetTwitterMembersList"
    />
    <advanced-tweet-search-modal
      v-if="isAdvancedTweetSearchModalOpened"
      :advancedSettingsToEdit="advancedSettingsToEdit"
      @close="closeTwitterAdvancedSearchModal"
      @create="createAndSaveAdvancedSearchSettings"
      @update="updateAndSaveAdvancedSearchSettings"
    />

    <upgrade-to-next-plan-pop-up
      :show="showNextPlanPopUp"
      :content="nextPlanPopUpContent"
      @close="showNextPlanPopUp = false"
      :nextPlan="nextPlan"
    />
  </div>
</template>

<script>
  import BackToTopCustom from '@/views/Widgets/BackToTopCustom';
  import controller from '@/controller';
  import dao from '@/dao';
  import FeedItemContainer from '@/components/FeedItemContainer';
  import FeedAndMentionsMixin from './Mixins/FeedAndMentionsMixin.vue';
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import SwalModalMixin from './Mixins/SwalModalMixin.vue';
  import TwitterListMembersModal from '@/views/Modals/TwitterListMembersModal.vue';
  import FeedAndMentionsShortcutsModal from '@/views/Modals/FeedAndMentionsShortcutsModal.vue';
  import AdvancedTweetsSearchMixin from './Mixins/AdvancedTweetSearchMixin.vue';
  import AdvancedTweetSearchModal from '@/views/Modals/AdvancedTweetSearchModal.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import UpgradeToNextPlanPopUp from '@/components/UpgradeToNextPlanPopUp.vue';
  import UpgradeToNextPlanMixin from './Mixins/UpgradeToNextPlanMixin.vue';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import { v4 as uuid } from 'uuid';
  import VirtualList from 'vue-virtual-scroll-list';
  import { EventBus } from '@/event-bus';
  import EngagementBuilderSideBar from '@/components/EngagementBuilder/EngagementBuilderSideBar.vue';
  import MobileEngagementSetting from '@/components/EngagementBuilder/MobileEngagementSetting.vue';

  export default {
    name: 'new-feed-page',
    data() {
      return {
        isFeedActions: false,
        isShortcutsModalVisible: false,
        isWatching: false,
        tweets: [],
        isTwitterListModalVisible: false,
        searchFeedTweets: [],
        isFetchingFeed: false,
        isFetchingTwitterLists: false,
        twitterLists: [],
        selectedList: {},
        maxAllowedWatchedUsersPerPlan: {
          premium: 100,
          standard: 15,
          none: 5,
        },
        maxAllowedWatchedSearchTermsPerPlan: {
          premium: 10,
          standard: 5,
          none: 1,
        },
        feedContainer: FeedItemContainer,
        userFilters: [],
        searchTermFilters: [],
        showMobileSettingsPage: false,
        usersWithNewTweets: [],
      };
    },
    mounted() {
      this.fetchUserLists();
      this.watchedUsers = lodash
        .get(this.userProfile, 'feedSettings.watchedUsers', [{ username: '' }]);

      this.updateWatchedUsersInfo();

      this.searchSettings = lodash.get(this.userProfile, 'feedSettings.searchSettings', []);

      if (this.canFetchFeed) {
        this.fetchFeed();
      }

      if (this.canFetchSearchFeed) {
        this.fetchSearchFeed();
      }

      EventBus.$on('hide-tweet', (tweetToHide) => {
        if (tweetToHide.type === 'search_terms_feed') {
          this.searchFeedTweets = this.searchFeedTweets.filter(
            (tweet) => tweet.id !== tweetToHide.id,
          );
        } else {
          this.tweets = this.tweets.filter((tweet) => tweet.id !== tweetToHide.id);
        }
      });
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
      canAddSearchSettings() {
        return this.searchSettings.length < this.maxAllowedWatchedSearchTerms;
      },
      numberOfWatchedUsers() {
        return [...this.watchedUsers].filter((username) => username).length;
      },
      areWatchedUsersMoreThanUserLimit() {
        return this.watchedUsers.length > this.maxAllowedWatchedUsers;
      },
      areWatchedSearchTermsMoreThanUserLimit() {
        return this.watchedSearchTerms.length > this.maxAllowedWatchedSearchTerms;
      },
      maxAllowedWatchedUsers() {
        return this.PLANS[this.userProfile.customerStatus].limits.feed.watchedUsers;
      },
      maxAllowedWatchedSearchTerms() {
        return this.PLANS[this.userProfile.customerStatus].limits.feed.watchedSearchTerms;
      },
      saveTooltipContent() {
        if (this.watchedUsers.filter((n) => n).length < 2) {
          return 'Add some users to watch';
        } else if (!this.isWatchedUsersValid) {
          return 'Remove duplicates from your watched users';
        } else if (this.searchSettings.length < 2) {
          return 'Add some search terms';
        } else {
          return 'Click to save';
        }
      },
      canFetchFeed() {
        return lodash.get(this.userProfile, 'feedSettings.watchedUsers', []).length >= 2;
      },
      canFetchSearchFeed() {
        return lodash.get(this.userProfile, 'feedSettings.searchSettings', []).length >= 1;
      },
      canSaveFeedSettings() {
        return this.watchedUsers.filter((n) => n).length >= 2 && this.isWatchedUsersValid;
      },
      isWatchedUsersValid() {
        return (
          new Set(this.watchedUsers.map((username) => username.trim().toLowerCase())).size ===
          this.watchedUsers.length
        );
      },
      searchTweetsByDate() {
        return lodash.groupBy(this.searchFeedTweets, (tweet) =>
          moment(tweet.createdAt).format('YYYY-MM-DD'),
        );
      },
      tweetsByDate() {
        const filteredSearchFeedTweets = this.searchTermFilters.length === 0 ?
          this.searchFeedTweets :
          this.searchFeedTweets.filter((tweet) => {
            return this.searchTermFilters.some((term) => tweet.searchTerm === term.exactPhrase);
          });
        const filteredUserFeedTweets = this.userFilters.length === 0 ?
          this.tweets :
          this.tweets.filter((tweet) => {
            return this.userFilters.includes(tweet.user.id);
          });

        const tweetsToShow = this.searchTermFilters.length ?
          filteredSearchFeedTweets :
          this.userFilters.length ?
          filteredUserFeedTweets :
          this.tweets;

        const tweets = tweetsToShow.sort((a, b) => {
          if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
            return 1;
          }
          if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
            return -1;
          }
          return 0;
        });
        const filteredTweets = lodash.groupBy(tweets, (tweet) =>
          moment(tweet.createdAt).format('YYYY-MM-DD'),
        );

        return this.flattenTweetsPerDay(filteredTweets);
      },
    },
    methods: {
      async updateHighEngagingUsersData(tweets) {
        const uniqueTwitterIds = [
          ...new Set(tweets.map((tweet) => tweet.user?.id).filter(Boolean)),
        ];

        const [pastInteractions, areHypefuryUsers] = await Promise.all([
          controller.twitter.getPastInteractions(
            this.currentUser,
            this.userProfile.twitterId,
            uniqueTwitterIds,
          ),
          controller.checkHypefuryUsers(this.currentUser, uniqueTwitterIds),
        ]);

        return tweets.map((tweet) => {
          const hasInteractedInPast = pastInteractions.find((interaction) => {
            return interaction.twitterId === tweet.user.id && interaction.interactionCount > 0;
          });
          const isFollowingUser = tweet.user.connection_status?.includes('followed_by');
          const isHypefuryUser = areHypefuryUsers[tweet.user.id];

          const isHigherEngaging = hasInteractedInPast || isFollowingUser || isHypefuryUser;
          return {
            ...tweet,
            isHigherEngaging,
          };
        });
      },
      nextPlansLimitsMessage(fieldName) {
        const plansAboveCurrentPlan = this.getPlansAboveCurrentPlan(
          this.userProfile.customerStatus,
        );
        if (plansAboveCurrentPlan.length === 0) {
          return '';
        } else {
          return `to increase the limit to ${plansAboveCurrentPlan
            .map(
              (plan) =>
                `${
                  plan.limits.feed[fieldName] === Infinity
                    ? 'unlimited'
                    : plan.limits.feed[fieldName]
                } (${plan.label} plan)`,
            )
            .join(' or ')}.`;
        }
      },
      async updateAndSaveAdvancedSearchSettings(index, settings) {
        this.updateAdvancedSearchSettings(index, settings);
        await this.saveFeedSettings();
      },
      async createAndSaveAdvancedSearchSettings(settings) {
        this.createAdvancedSearchSettings(settings);
        await this.saveFeedSettings();
      },
      async deleteAndSaveAdvancedSearchSetting(i) {
        this.deleteAdvancedSearchSettings(i);
        await this.saveFeedSettings();
      },
      async watchedUserBlur(i) {
        if (this.watchedUsers[i]) {
          await this.saveFeedSettings();
        }
      },
      openTwitterListMembersModal() {
        this.isTwitterListModalVisible = true;
        this.$eventStore.engagementBuilder.settings('Import users');
      },
      closeTwitterListMembersModal() {
        this.isTwitterListModalVisible = false;
      },
      resetTwitterMembersList() {
        this.selectedList = {};
      },
      async setWatchedUsersFromTwitterList(members) {
        this.resetTwitterMembersList();
        const allUsers = [...this.watchedUsers, ...members];
        const updateMap = {
          'feedSettings.watchedUsers': lodash.uniqBy(allUsers, 'twitterId'),
          'feedSettings.isFeedSettingsUpdatedRecently': true,
        };
        await dao.userProfile.updateFeedSettings(this.userProfile.uid, updateMap);
        this.fetchFeed();
      },
      showFeedActions() {
        this.isFeedActions = true;
      },
      showWatching() {
        this.isWatching = true;
      },
      addWatchedUserField() {
        this.watchedUsers.push('');
        this.$eventStore.engagementBuilder.settings('Add user');
      },
      async fetchUserLists() {
        try {
          this.isFetchingTwitterLists = true;
          this.twitterLists = await controller.twitter.getTwitterLists(
            this.currentUser,
            this.userProfile.uid,
          );
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Error',
            html: 'An error occurred while trying to fetch your Twitter lists.',
            type: 'warning',
          });
        } finally {
          this.isFetchingTwitterLists = false;
        }
      },
      fetchSearchFeed: lodash.debounce(async function() {
        this.isFetchingFeed = true;
        try {
          const fetchedSearchFeed = await controller.twitter.searchTweets(
            this.currentUser,
            this.userProfile.uid,
          );
          const feedInteractions = lodash.get(
            this.userProfile,
            'integrations.twitter.searchFeedInteractions',
            [],
          );
          const authorIds = fetchedSearchFeed.map((tweet) => tweet.user.id);
          const { data: followedAuthorIds } = await controller.twitter.getFollowingsByAuthorIds(
            authorIds,
            this.currentUser,
            this.userProfile.uid,
          );
          const searchFeedResult = fetchedSearchFeed.reduce((result, tweet) => {
            if (followedAuthorIds.includes(tweet.user.id)) {
              return [tweet, ...result];
            }
            return [...result, tweet];
          }, []);
          this.searchFeedTweets = searchFeedResult
            .filter((tweet) => !(feedInteractions[tweet.id] && feedInteractions[tweet.id].isHidden))
            .map((tweet) => {
              return { ...tweet, type: 'search_terms_feed' };
            });
        } catch (error) {
          console.error(error);
          this.isFetchingFeed = false;
          this.swalModal({
            title: 'Error',
            html: 'An error occurred while fetching your feed.',
            type: 'warning',
          });
        }
        this.isFetchingFeed = false;

        this.searchFeedTweets = await this.updateHighEngagingUsersData(this.searchFeedTweets);
      }, 1000),
      fetchFeed: lodash.debounce(async function() {
        this.isFetchingFeed = true;
        try {
          const fetchedFeed = await controller.twitter.fetchFeed(
            this.currentUser,
            this.userProfile.uid,
          );
          const feedInteractions = lodash.get(
            this.userProfile,
            'integrations.twitter.feedInteractions',
            [],
          );

          const usersWithNewTweets = new Set();

          this.tweets = fetchedFeed
            .filter((tweet) => !(feedInteractions[tweet.id] && feedInteractions[tweet.id].isHidden))
            .map((tweet) => {
              if (tweet.isNew) {
                usersWithNewTweets.add(tweet.user.id);
              }
              return { ...tweet, type: 'watched_users_feed' };
            });

          this.usersWithNewTweets = Array.from(usersWithNewTweets);
        } catch (error) {
          console.error(error);
          this.isFetchingFeed = false;
          this.swalModal({
            title: 'Error',
            html: 'An error occurred while fetching your feed.',
            type: 'warning',
          });
        }
        this.isFetchingFeed = false;

        this.tweets = await this.updateHighEngagingUsersData(this.tweets);
      }, 1000),
      async removeWatchedUser(i) {
        this.watchedUsers.splice(i, 1);
        await this.saveFeedSettings();
      },
      async saveFeedSettings() {
        try {
          await this.updateFeedSettings();
        } catch (e) {
          return;
        }
      },
      flattenTweetsPerDay(tweetPerDay) {
        const result = [];

        for (const date in tweetPerDay) {
          result.push({ id: uuid(), isDate: true, date: date });
          result.push(...tweetPerDay[date]);
        }

        return result;
      },
      filterByUser(userTwitterId) {
        this.tweets = this.tweets.filter((tweet) => tweet.user.id === userTwitterId);
      },
      updateUserFilter(userTwitterId) {
        if (userTwitterId === null) {
          this.userFilters = [];
        } else if (this.userFilters.find(userId => userId === userTwitterId)) {
          this.userFilters = [];
        } else {
          this.userFilters = [userTwitterId];
        }
      },
      updateSearchTermFilter(searchTerm) {
        if (searchTerm === null) {
          this.searchTermFilters = [];
        } else if (this.searchTermFilters.find(t => t.exactPhrase === searchTerm.exactPhrase)) {
          this.searchTermFilters = [...this.searchTermFilters.filter(t => t.exactPhrase !== searchTerm.exactPhrase)];
        } else {
          this.searchTermFilters = [...this.searchTermFilters, searchTerm];
        }
      },
      async updateWatchedUsersInfo() {
        const updatedWatchedUsers = await controller.twitter.getTwitterUsersByUsernames(
          this.currentUser,
          this.userProfile.uid,
          this.watchedUsers.map((user) => user.username),
        );

        const updateMap = {
          'feedSettings.watchedUsers': updatedWatchedUsers,
        };

        dao.userProfile.updateFeedSettings(this.userProfile.uid, updateMap);
      },
    },
    components: {
      BackToTopCustom,
      EngagementBuilderSideBar,
      // eslint-disable-next-line vue/no-unused-components
      FeedItemContainer,
      TwitterListMembersModal,
      FeedAndMentionsShortcutsModal,
      AdvancedTweetSearchModal,
      UpgradeToNextPlanPopUp,
      VirtualList,
      MobileEngagementSetting,
    },
    mixins: [
      FeedAndMentionsMixin,
      SwalModalMixin,
      AdvancedTweetsSearchMixin,
      BreakpointsMixin,
      CustomerStatusMixin,
      UpgradeToNextPlanMixin,
    ],
    beforeDestroy() {
      EventBus.$off('hide-tweet');
    },
  };
</script>
<style lang="scss" scoped>
  .advanced-search-settings-container {
    width: 100%;
    color: var(--color-primary);
    position: relative;
    border-width: 1px;
    border-color: var(--input-border);
    height: 2.5rem;
    padding-inline: 0.75rem;
    padding-block: 0.5rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 0.375rem;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
  }
</style>
